import React, { FC } from 'react'
import { ReactComponent as Info } from '../asset/icon-question-green.svg'

interface Props {
  title: string
  value?: string
  action?: string
  onClick?: () => void
  last?: boolean
  hideAction?: boolean
  question?: boolean
  dataTestId?: string
}

const ListItem: FC<Props> = ({ title, value, action, onClick, last, hideAction = false, question, dataTestId }) => {
  return (
    <div className={`list-item ${!last ? 'first' : 'last'}`} data-testid={dataTestId}>
      <div className="description">
        <div className="list-title" data-testid="label">{title}</div>
        <div className="list-value" data-testid="text">{value}</div>
      </div>
      {!hideAction && (
        <div className={`list-action ${question && 'question'}`} data-testid={`button-${action?.toLowerCase()}`} onClick={() => onClick && onClick()}>
          {!question ? action : <Info />}
        </div>
      )}
    </div>
  )
}

export default ListItem

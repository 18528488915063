import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'
import QRCode from 'react-qr-code'
import { Tooltip } from 'react-tooltip'

import i18n from '../../i18n'

import { Button, IconListItem, Layout, Text, Title, Spinner, LinkButton } from '../../components'
import { ReactComponent as PlusIcon } from '../../asset/icon-plus-green.svg'
import { ReactComponent as ComputerIcon } from '../../asset/icon-computer-green.svg'
import { ReactComponent as PhoneIcon } from '../../asset/icon-phone-green.svg'
import { ReactComponent as ShowMeIcon } from '../../asset/icon-show-me.svg'
import { ReactComponent as CopyIcon } from '../../asset/icon-copy.svg'
import { PageI, errorI } from '../../components/ComponentInterface'
import { useTriggeredAuthenticatorRegister } from '../../services'
import { useContext, updateError } from '../../Context'
import ROUTES from '../../routes/common/constants'

const AddAuthenticatorAppPage = ({ goBackLink, successFunc }: PageI) => {
  const [textCopied, setTextCopied] = useState(false)
  const [authenticatorSecret, setAuthenticatorSecret] = useState('')
  const { useAuthenticatorRegister: authenticatorRegister, isLoading } = useTriggeredAuthenticatorRegister()
  const { dispatch } = useContext()

  // error
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' })

  useEffect(() => {
    // Authenticator REgister API Call
    const handleRegisterAuthenticator = async () => {
      try {
        if (!authenticatorSecret && !errorObj.error) {
          const { response, error } = await authenticatorRegister({
            data: { method: 'TOTP' },
          })

          if (response.isSuccess) {
            setAuthenticatorSecret(response?.data?.secret)
          } else if (error.isUnauthorizedError) {
            dispatch(updateError(true))
          } else {
            setErrorObj({
              ...errorObj,
              error: 'error-500-1',
            })
          }
        }
      } catch (e) {
        console.log('e', e)
        setErrorObj({
          ...errorObj,
          error: 'error-500-1',
        })
      }
    }

    handleRegisterAuthenticator()

    // eslint-disable-next-line
  }, [authenticatorSecret, errorObj])

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(authenticatorSecret)
      .then(() => {
        setTextCopied(true)
        handleCopiedToolTip()
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  // TIMER FOR TOOLTIP AFTER COPY
  const handleCopiedToolTip = () => {
    setTimeout(() => {
      setTextCopied(false)
    }, 3000)
  }

  const handleQrSection = () => {
    if (authenticatorSecret) {
      const qrValue = `otpauth://totp/PEI%20ID?secret=${authenticatorSecret}&issuer=PEI%20ID`
      return (
        <>
          <QRCode size={175} value={qrValue} />

          <div className="instruction-box">
            <Text>{parse(i18n.t('setup-authenticator-app-qr-page-keycode'))}</Text>
            <Text dataTestId="code">{authenticatorSecret}</Text>
            <IconListItem logo={<CopyIcon />} dataTestId="tap-copy">
              <button data-tooltip-id="select" onClick={handleCopyClick}>{`${i18n.t('setup-authenticator-app-tap-to-copy')}`}</button>
              {textCopied ? <Tooltip id={'select'} content={i18n.t('Copied')} place={'right'} isOpen={textCopied} /> : null}
            </IconListItem>
          </div>
        </>
      )
    }
  }

  return (
    <Layout
      header
      footer
      title={i18n.t('setup-authenticator-app-qr-page-title')}
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      className="authenticator-app-flow progress-bar"
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={5}
    >
      <Title title={i18n.t('setup-authenticator-app-qr-page-title')} />
      <div className="content-container">
        <div>
          <Text className="subtitle" dataTestId="lead-text">
            {parse(i18n.t('setup-authenticator-app-qr-page-subtitle'))}
          </Text>
          <div className="content-body-container">
            <div className="left">
              <IconListItem logo={<PlusIcon />} dataTestId="step-2">
                <Text>{parse(i18n.t('setup-authenticator-app-qr-page-step-2'))}</Text>
              </IconListItem>
              <IconListItem logo={<ComputerIcon />} dataTestId="step-3-option1">
                <div>
                  <Text>{parse(i18n.t('setup-authenticator-app-qr-page-step-3-option1'))}</Text>
                </div>
              </IconListItem>
              <Text className="or-text">{parse(i18n.t('or'))}</Text>
              <IconListItem logo={<PhoneIcon />} dataTestId="step-3-option2">
                <Text>{parse(i18n.t('setup-authenticator-app-qr-page-step-3-option2'))}</Text>
              </IconListItem>

              <div className="links-container">
                <IconListItem logo={<ShowMeIcon />} dataTestId="Show-how">
                  {/* TODO UPDATE THE REDIRECT URL TO SHOW PAGE */}
                  <LinkButton target="_blank" text={i18n.t('setup-authenticator-app-show-me-link')} url={ROUTES.AccountSetupHelpRoute} />
                </IconListItem>
              </div>

              <div className="button-container-2">
                <Button secondary text={i18n.t('setup-authenticator-app-back')} onClick={goBackLink} dataTestId="Button-secondary" />
                <Button text={i18n.t('continue')} onClick={successFunc} dataTestId="Button-primary" />
              </div>
            </div>
          </div>
        </div>
        <div className="qr-code">{isLoading ? <Spinner dark /> : handleQrSection()}</div>
      </div>
    </Layout>
  )
}

export default AddAuthenticatorAppPage

import React, { useState } from 'react'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { Layout, SignInForm, AccessPeiForm, Title, Notification, Button } from '../../components'
import { PageI, errorI } from '../../components/ComponentInterface'

const SignInPage = ({ successFunc, otherRoutes }: PageI) => {
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' })

  return (
    <>
      <Layout header footer className="sign-in" title={i18n.t('sign-in-title')}>
        <Title title={i18n.t('sign-in-title')} />
        {errorObj.errorNotification && <Notification text={parse(i18n.t(errorObj.error))} className="error" dataTestId="banner-error" />}

        <SignInForm successFunc={successFunc} errorObj={errorObj} setErrorObj={setErrorObj} />
        <div className='post-sign-in-form'>
          <div className='sign-up-redirect'>
            <h2>{parse(i18n.t('sign-in-no-account'))}</h2>
            <p dangerouslySetInnerHTML={{ __html: i18n.t('sign-in-create-account-content') }} />
            <Button onClick={otherRoutes?.createAccount} text={i18n.t('create-account')} dataTestId="Button-secondary" />
          </div>
          <AccessPeiForm successFunc={otherRoutes?.inPersonAccount} errorObj={errorObj} setErrorObj={setErrorObj} />
        </div>
      </Layout>
    </>
  )
}

export default SignInPage

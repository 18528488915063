import React from 'react'
import parse from 'html-react-parser'

import iconArrow from '../asset/icon-table-arrow.svg'
import i18n from '../i18n'

interface TableProps<T> {
  data: T[]
  columns: { label: string; key: string }[]
  isLink?: boolean
  onClickRow: (row: any) => void
}

const TableLink = ({ link }: { link: string }) => {
  return (
    <div className="link-container">
      <div className="link">{link}</div>
      <img src={iconArrow} alt="arrow link" />
    </div>
  )
}

const Table: React.FC<TableProps<any>> = ({ data, columns, isLink, onClickRow }) => {
  return (
    <table className="table-view">
      <thead>
        <tr>
          {columns.map((column) => (
            <th className={'title'} key={column.key}>
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, indexRow) => {
          const { disabled } = row
          return (
            <tr key={indexRow}>
              {columns.map((column, index) => {
                return (
                  <td key={column.key}>
                    {index === 0 ? (
                      isLink ? (
                        <div data-testid={`row${indexRow}`} onClick={() => onClickRow(row)}>
                          <TableLink link={row[column.key]} />
                        </div>
                      ) : (
                        <div data-testid={`${column.key}-${indexRow}`} className="infomation">
                          {disabled && (
                            <div className="disabled-info" data-testid={`revoke${indexRow}`}>
                              {parse(i18n.t('wallet-consent-history-details-revoked'))}
                            </div>
                          )}
                          <div className="data">{row[column.key]}</div>
                        </div>
                      )
                    ) : (
                      <div className="row-data" data-testid={`${column.key}-${indexRow}`}>
                        {row[column.key]}
                      </div>
                    )}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table

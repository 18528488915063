import React from 'react'
import parse from 'html-react-parser'

import { CardI } from '../ComponentInterface'
import { ReactComponent as LightbulbIcon } from '../../asset/lightbulb-grey.svg'

const Card = ({ icon, title, description, reminder, onClick, className, dataTestId }: CardI) => {
  return (
    <button className={`card ${className ? className : ''}`} tabIndex={0} onClick={onClick} data-testid={dataTestId}>
      <div className="body">
        <h4 data-testid="heading">{title}</h4>
        <p data-testid="text">{parse(`${description}`)}</p>
      </div>
      <div className="reminder">
        <div>
          {reminder && (
            <div className="body-reminder">
              <LightbulbIcon />
              <p>{reminder}</p>
            </div>
          )}
        </div>
        <i>{icon}</i>
      </div>
    </button>
  )
}

export default Card

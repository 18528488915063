import React, { useState } from 'react'
import i18n from '../i18n'
import Button from './buttons/Button'
import { HeaderI } from './ComponentInterface'
import { useHistory } from 'react-router-dom'

import logo from '../asset/logo.svg'
import logo2 from '../asset/MyPEI-logo-white.svg'
import Menu from './Menu'
import IconButton from './buttons/IconButton'
import { ReactComponent as IconMenu } from '../asset/icon-menu.svg'
import { ReactComponent as IconX } from '../asset/icon-x.svg'
import ROUTES from '../routes/common/constants'

const Header = ({ logout }: HeaderI) => {
  const history = useHistory()
  const logoutClick = () => history.push(ROUTES.LogoutRoute)

  // menu
  const [isMenuOpen, setMenuOpen] = useState(false)

  const menuClick = () => {
    setMenuOpen(!isMenuOpen)
  }

  const menuData = [{ icon: '', title: i18n.t('language-select'), link: '' }, ...(logout ? [{ icon: '', title: i18n.t('sign-out'), link: logoutClick }] : [])]

  return (
    <header data-testid="Header">
      <div className="header-container">
        <div className="header-main">
          <img src={logo} alt={'PEI'} />
          <img src={logo2} alt={'PEI'} />
        </div>
        <div className="header-buttons">
          <Button
            // TO DO: language-switching functionality required
            text={i18n.t('language-select')}
            className="header-button"
          />

          {logout && <Button onClick={logoutClick} text={i18n.t('sign-out')} className="header-button header-signout" />}
        </div>
        <IconButton onClick={menuClick} icon={isMenuOpen ? <IconX /> : <IconMenu />} />
      </div>
      <div className="header-bottom"></div>

      {isMenuOpen && <Menu data={menuData} />}
    </header>
  )
}

export default Header

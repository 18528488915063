import React from 'react'
import i18n from '../../i18n'

import { PageI } from '../../components/ComponentInterface'
import { Layout, TermsOfServiceForm } from '../../components'

const TermsOfServicePage = ({ goBackLink, successFunc }: PageI) => {
  return (
    <Layout header title={i18n.t('terms-of-service-title')} backBtnCallback={goBackLink} backText={i18n.t('back')} className="terms-of-service" footer>
      <TermsOfServiceForm successFunc={successFunc} />
    </Layout>
  )
}

export default TermsOfServicePage

import React from "react";
import { Layout, PasscodeForm, Text, Title } from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { PageI } from "../../components/ComponentInterface";

const OTPage = ({
	successFunc,
	goBackLink,
	otherRoutes: { apiType = "createAccount" } = {},
}: PageI) => {
	return (
		<Layout
			header
			footer
			title={i18n.t("one-time-password-title")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			className="one-time-password-form progress-bar"
			stepper
			stepperLabel={i18n.t("account-creation")}
			stepperNumberofSteps={6}
			stepperCurrentStep={2}
		>
			<Title title={i18n.t("one-time-password-title")} />
			<Text className="lead-text">
				{parse(i18n.t("one-time-password-description"))}
			</Text>
			<div className="code-form">
				<PasscodeForm
					successFunc={successFunc}
					apiType={apiType}
					twoFaMethod="EMAIL"
				/>
			</div>
		</Layout>
	);
};

export default OTPage;

import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { TokenCallback } from '../../../../wallet'
import { useWallet } from '../../../../context/wallet'

import { Spinner } from '../../../../components'
import ROUTES from '../../../../routes/common/constants'
import LocalStorage from '../../../../services/LocalStorage'
import { ECallbackError } from '../../../../wallet/types/wallet'
import { getServices } from '../../../../wallet/services/ServiceRegistry'
// import { getTxContinueById } from '../../../../wallet/apis/user/Transactions'

const WalletAuthCallback = () => {
  const {
    Idp: { handleCallback },
    Consent: { isDeferred },
  } = useWallet()

  const history = useHistory()
  const { storage, config } = getServices()

  const handleContinue = async () => {
    const transaction_id = storage.get('cr_token')
    storage.delete('cr_token')
    storage.delete('auto_consent')
    window.location.href = config.ws_url + '/tx/' + transaction_id + '/continue'
  }

  useEffect(() => {
    const query: TokenCallback = queryString.parse(window.location.search) as any

    const { error } = query
    const autoConsent = storage.get('auto_consent')

    if (error === ECallbackError.invalid_sessin) {
      history.push(ROUTES.LandingPage)
      return
    }

    handleCallback(query).then(() => {
      if (autoConsent) {
        handleContinue()
        return
      }

      if (isDeferred) {
        history.push(`${ROUTES.WalletConsentCallback}?token=${LocalStorage.get('cr_token')!}&client=${LocalStorage.get('client')!}`)
      } else {
        history.push(ROUTES.WalletProfile)
      }
    })
    // eslint-disable-next-line
  }, [])

  return (
    <div className="wallet-layout">
      <div className="loading">
        <Spinner dark />
      </div>
    </div>
  )
}

export default WalletAuthCallback

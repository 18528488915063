import { IWalletClientStorage, TransactionResponse } from '../wallet'

const ConsentRequestTokenHistory = (storage: IWalletClientStorage) => {
  const add = (transaction: TransactionResponse) => {
    const transactionMap = storage.get('cr_token_history', true)

    storage.set(
      'cr_token_history',
      {
        ...transactionMap,
        [transaction.transaction_id]: transaction,
      },
      true,
    )
  }

  const remove = (transaction_id: string) => {
    const tokenHistory = storage.get('cr_token_history')

    if (tokenHistory === null) {
      throw new Error('No record of consent history')
    }

    delete tokenHistory[transaction_id]

    storage.set('cr_token_history', tokenHistory, true)
  }

  const get = (transaction_id: string) => {
    const cr_token_history = storage.get('cr_token_history', true)

    if (cr_token_history === null) {
      throw new Error('No record of consent history')
    }

    return cr_token_history[transaction_id]
  }

  return { add, remove, get }
}

export default ConsentRequestTokenHistory

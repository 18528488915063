import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'

import i18n from '../../../../i18n'

import useIsMobile from '../../../../common/hooks/useIsMobile'

import { Button, Layout, LinkButton, Modal, Spinner, Title } from '../../../../components'

import consentFlow from '../../../../asset/consent-flow.png'
import consentFlowMobile from '../../../../asset/consent-flow-mobile.png'

import profileImg from '../../../../asset/consent-profile.svg'
import useConsent from '../../hooks/useConsent'
import { useTriggeredProfile } from '../../../../services'

type ConsentUrlParams = {
  transactionID: string
}

const WalletConsentePage: FC = () => {
  const { transactionID } = useParams<ConsentUrlParams>()
  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false)
  const [isScopesModalOpen, setScopesModalOpen] = useState(false)
  const [scopeName, setScopeName] = useState({ client: '', scope: '' })
  const [consentExpireAt, setConsentExpireAt] = useState('')
  const [userName, setUserName] = useState('')
  const isMobile = useIsMobile()

  const {
    generateListOfScopeName,
    generateClientName,
    getScopeDefinitionRequested,
    getMyDataSources,
    handleCreatePermission,
    handleAgreeButtonClick,
    handleDisagreeButtonClick,
    isLoadingPage,
    isLoadingSubmit,
    myDsas,
  } = useConsent(transactionID!)

  const listOfScopes = getScopeDefinitionRequested()
  const { useProfile: profile } = useTriggeredProfile()

  const getProfile = async () => {
    const { response } = await profile()

    if (response.isSuccess) {
      const {
        data: { first_name, last_name, preferred_name },
      } = response

      if (preferred_name && last_name) {
        setUserName(`${preferred_name} ${last_name}`)
      } else if (first_name && last_name) {
        setUserName(`${first_name} ${last_name}`)
      } else {
        setUserName(`${last_name}`)
      }
    }
  }

  useEffect(() => {
    getMyDataSources()
    handleCreatePermission()
    getProfile()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!scopeName?.client) {
      setScopeName({ client: generateClientName(), scope: generateListOfScopeName() })
    }
  }, [scopeName, generateClientName, generateListOfScopeName])

  useEffect(() => {
    if (myDsas) {
      const [userInfo] = myDsas
      const {
        rot: { expires_at },
      } = userInfo
      setConsentExpireAt(expires_at)
    }
  }, [myDsas])

  return (
    <>
      {isLoadingPage ? (
        <div className="wallet-layout">
          <div className="loading">
            <Spinner dark />
          </div>
        </div>
      ) : (
        <Layout header footer logout>
          <div className="wallet-consent-page">
            <div className="wallet-consent-profile">
              <img src={profileImg} alt="profile"></img>
              <div data-testid="name">{userName}</div>
            </div>
            <Title title={i18n.t('wallet-consent-title', { service: scopeName.scope })} dataTestid={'list-of-resources-title'} />
            {isMobile && (
              <div className="wallet-consent-section-secondary">
                <img src={consentFlowMobile} alt="pei"></img>
              </div>
            )}
            <div className="wallet-consent-subtitle" data-testid={'list-of-resources'}>
              {parse(i18n.t('wallet-consent-subtitle', { consent: scopeName.client, scopes: scopeName.scope }))}
            </div>
            <div className="wallet-consent-auth" data-testid={'expire-date'}>
              {parse(i18n.t('wallet-consent-auth', { date: consentExpireAt && new Date(consentExpireAt).toISOString().split('T')[0] }))}{' '}
            </div>

            <div className="wallet-consent-section">
              <div className="wallet-consent-section-primary">
                <div data-testid={'body'} className="wallet-consent-link" onClick={() => setScopesModalOpen(true)}>
                  {parse(i18n.t('wallet-consent-btn-link', { application: scopeName.client }))}
                </div>
                <div className="wallet-consent-btn">
                  <Button secondary onClick={() => setDeclineModalOpen(true)} text={i18n.t('wallet-consent-btn-decline')} dataTestId={'Button-secondary'} />
                  <Button isLoading={isLoadingSubmit} onClick={handleAgreeButtonClick} text={i18n.t('wallet-consent-btn-allow')} dataTestId={'Button-primary'} />
                </div>
                <div className="wallet-consent-terms-privacy">
                  <div className="wallet-consent-link small" data-testid={'terms-of-service'}>
                    <LinkButton url={``} text={parse(i18n.t('wallet-consent-terms'))} dataTestId="Button-link" />
                  </div>
                  <div className="small">{parse(i18n.t('wallet-consent-and'))}</div>
                  <div className="wallet-consent-link small" data-testid={'privacy-policy'}>
                    <LinkButton url={``} text={parse(i18n.t('wallet-consent-privacy'))} dataTestId="Button-link" />
                  </div>
                </div>
              </div>
              {!isMobile && (
                <div className="wallet-consent-section-secondary">
                  <img src={consentFlow} alt="pei description"></img>
                </div>
              )}
            </div>
          </div>

          <Modal toggleModal={() => setDeclineModalOpen(false)} isModalOpen={isDeclineModalOpen} className="wallet-decline-modal">
            <div>
              <div className={'title'}>{parse(i18n.t('wallet-consent-btn-confirm-decline-title'))}</div>
              <div>{parse(i18n.t('wallet-consent-btn-confirm-decline-desc', { service_name: scopeName.client }))}</div>
              <div className="decline-btn">
                <Button className={'go-back'} secondary onClick={() => setDeclineModalOpen(false)} text={i18n.t('wallet-consent-btn-go-back')} dataTestId={'decline-go-back '} />
                <Button className={'decline'} onClick={handleDisagreeButtonClick} text={i18n.t('wallet-consent-btn-confirm-decline')} dataTestId={'decline-yes'} />
              </div>
            </div>
          </Modal>

          <Modal toggleModal={() => setScopesModalOpen(false)} isModalOpen={isScopesModalOpen} className="wallet-scopes-modal">
            <div>
              {listOfScopes.map(({ resource_definition: { name }, scopes_requested }, listIndex) => {
                return (
                  <div className="container" key={listIndex}>
                    <div className="title" data-testid={`modal-list-${listIndex}`}>
                      {name}
                    </div>
                    <div>{parse(i18n.t('wallet-consent-scopes-modal-include'))}</div>
                    <div>
                      {scopes_requested.map(({ display_name, purpose }, scopeIndex) => {
                        return (
                          <div className="scope-section" key={scopeIndex}>
                            <div className="scope" data-testid={`scope-name-${listIndex}-${scopeIndex}`}>
                              {display_name}
                            </div>
                            <div data-testid={`scope-description-${listIndex}-${scopeIndex}`}>{purpose}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </Modal>
        </Layout>
      )}
    </>
  )
}

export default WalletConsentePage

import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import ROUTES from '../routes/common/constants'
import useSessionValidation from '../common/hooks/useSessionValidation'
import SessionModal from '../components/session/SessionModal'

const scrollTop = () => window.scrollTo(0, 0)

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const { token, checkIDStoreToken } = useSessionValidation()

  useEffect(() => {
    checkIDStoreToken()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <SessionModal />
      <Route
        {...rest}
        render={() => {
          scrollTop()
          return token && <Component />
        }}
      />
    </>
  )
}

export const DefaultRoute = ({ ...rest }) => {
  return <Route {...rest} render={() => <Redirect to={{ pathname: ROUTES.LandingPage }} />} />
}

export const PublicRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={() => {
        scrollTop()
        return <Component />
      }}
    />
  )
}

export default ProtectedRoute

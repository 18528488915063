import React from 'react'
import parse from 'html-react-parser'
import i18n from '../../../../../i18n'

const DeleteAccount = () => {
  return (
    <div className="profile-information-session delete-account">
      <div className="divider"></div>
        <div className="description">
          <div className={`list-item`}>
            <div className="description">
              <div className="list-value">{parse(i18n.t('wallet-profile-delete-account'))}</div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default DeleteAccount

import moment from 'moment'
import i18n from '../i18n'

export const addSessionStorage = (key: string, data: any) => {
  sessionStorage.setItem(key, data)
}

export const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key)
}

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key)
}

export const nameRegex = (value: string) => {
  // eslint-disable-next-line
  const regex = /^[A-Za-zÀ-ÿ\s'-\.]+$/
  return value === '' || regex.test(value)
}

export const postalCodeRegex = (value: string) => {
  const regex = new RegExp(/^([aA-zZ]|$)([0-9]|$)([aA-zZ]|$)([0-9]|$)([aA-zZ]|$)([0-9]|$)/)
  return regex.test(value.split(' ').join(''))
}

export const versionCodeRegex = (value: string) => {
  const regex = new RegExp(/^([aA-zZ]|$){2}/)
  return regex.test(value.split(' ').join(''))
}

export const healthCardNumberRegex = (value: string) => {
  const regex = new RegExp(/^[0-9]*$/)
  return regex.test(transformText(value))
}

export const documentIdentifierRegex = (value: string) => {
  const regex = new RegExp(/^([aA-zZ]|$)([aA-zZ]|$)([0-9]|$){7}/)
  return regex.test(transformText(value))
}

export const numberRegex = (value: string) => {
  const regex = new RegExp(/^[0-9]*$/)
  return regex.test(transformText(value))
}

export const ontarioPhotoCardRegex = (value: string) => {
  const regex = new RegExp(/^([0-9]|$)([0-9]|$)([0-9]|$)([aA-zZ]|$)([aA-zZ]|$)([0-9]|$){7}/)
  return regex.test(transformText(value))
}

export const driverLicenceRegex = (value: string) => {
  const regex = new RegExp(/^([aA-zZ]|$)([0-9]|$){14}/)
  return regex.test(transformText(value))
}

export const transformText = (text: string) => text.split('-').join('')

export const capitalizeText = (text: string) => {
  return text
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const maskMonthDate = (text: string) => {
  return text.replace(/\/\d{2}/g, '/**')
}

export const formatPostalCode = (text: string) => {
  if (text.length === 6) {
    return text.substring(0, 3) + ' ' + text.substring(3, 6)
  } else if (text.length === 7) {
    return text.substring(0, 3) + ' ' + text.substring(4, 7)
  } else {
    return text
  }
}

export const maskEmail = (text: string) => {
  const split = text.split('@')
  const splitDomain = split[1].split('.')
  return (
    text.substr(0, 1) +
    new Array(split[0].length - 2).fill('*').join('') +
    split[0].substr(split[0].length - 1, split[0].length) +
    '@' +
    split[1].substr(0, 1) +
    new Array(split[1].length - 2 - splitDomain[splitDomain.length - 1].length).fill('*').join('') +
    splitDomain[splitDomain.length - 2].slice(-1) +
    '.' +
    splitDomain[splitDomain.length - 1]
  )
}

export const maskHealthCard = (text: string) => {
  return new Array(text.length - 2).fill('*').join('') + text.substr(text.length - 2, text.length)
}

export const maskId = (text: string) => {
  return text.slice(0, -2).replace(/./g, '*') + text.slice(-2)
}

// eslint-disable-next-line
export const removeDashSpaceSpecialChar = (text: string) => {
  // eslint-disable-next-line
  return (
    text
      .replace(/-/g, '')
      .replace(/ /g, '')
      .replace(/\//g, '')
      // eslint-disable-next-line
      .replace(/[`~!@#$%^&*()_|+\-=?;:'',.<>\{\}\[\]\\\/]/gi, '')
  )
}

export const addDashesIndex = (text: string, index?: Array<number>) =>
  text
    .replace(/-/g, '')
    .split('')
    .reduce((acc, curr, i) => acc + (index?.includes(i) ? (curr += '-') : curr), '')

export const addSpaceIndex = (text: string, index: Array<number>) =>
  text
    .replace(/ /g, '')
    .split('')
    .reduce((acc, curr, i) => acc + (index.includes(i) ? (curr += ' ') : curr), '')

export const addSlashIndex = (text: string, index: Array<number>) =>
  text
    .replace(/\//g, '')
    .split('')
    .reduce((acc, curr, i) => acc + (index.includes(i) ? (curr += '/') : curr), '')

export const setDashesIndex = (text: string, index: Array<number>, maxLength: number) => {
  let transformedText = addDashesIndex(removeDashSpaceSpecialChar(text), index)
  return (transformedText.slice(-1) === '-' ? transformedText.slice(0, -1) : transformedText).slice(0, maxLength)
}

export const setSpaceIndex = (text: string, index: Array<number>, maxLength: number) => {
  let transformedText = addSpaceIndex(removeDashSpaceSpecialChar(text), index)
  return (transformedText.slice(-1) === ' ' ? transformedText.slice(0, -1) : transformedText).slice(0, maxLength)
}

export const setSlashIndex = (text: string, index: Array<number>, maxLength: number) => {
  let transformedText = addSlashIndex(removeDashSpaceSpecialChar(text), index)
  return (transformedText.slice(-1) === '/' ? transformedText.slice(0, -1) : transformedText).slice(0, maxLength)
}

export const setFullDateInput = (e: any, updateDateFunc: () => void, isExpiryDate?: boolean) => {
  const removeDashesText = removeDashSpaceSpecialChar(e)
  const year = Number(removeDashesText.slice(0, 4))
  const month = Number(removeDashesText.slice(4, 6))
  const date = Number(removeDashesText.slice(6, 8))
  const minDate = '1900-01-01'
  const todayDate = isExpiryDate ? moment(new Date()).add(10, 'y').format(i18n.t('dob-placeholder')) : new Date().toISOString().slice(0, 10)
  const monthNum1 = Number(removeDashesText.slice(4, 5))

  // validate input is equal or greater than today date input year
  const yearCompare = (yearDigit: number) => {
    const minDateNum = Number(minDate.slice(0, yearDigit))
    const todayDateNum = Number(todayDate.slice(0, yearDigit))
    const isBetweenMinTodayDate = Number(removeDashesText) >= minDateNum && Number(removeDashesText) <= todayDateNum
    return isBetweenMinTodayDate || removeDashesText === ''
  }

  // validate first 3 digits of year (e.g. validate 2, 20, 202)
  if (removeDashesText.length <= 3 && yearCompare(removeDashesText.length)) updateDateFunc()

  switch (removeDashesText.length) {
    // validate 4 digit year is between minYear and current year (e.g. validate 2022
    case 4:
      moment(removeDashesText).isBetween(minDate.slice(0, 4), todayDate.slice(0, 4), undefined, '[]') && updateDateFunc()
      break
    case 5:
      // validate first digit of month (e.g. validate 2022-0)
      ;(monthNum1 === 0 || monthNum1 === 1) && updateDateFunc()
      break
    case 6:
      // validate 2 digits of month (e.g. validate 2022-02)
      moment(setDashesIndex(e, [3, 5], 10)).isBetween(minDate.slice(0, 7), todayDate.slice(0, 7), undefined, '[]') && updateDateFunc()
      break
    case 7:
      // validate first digits of date (e.g. validate 2022-02-1)
      // if user types 2022-02-3 this is valid in moment.js because it's seen as 2022-02-03. so changing it to be 2022-03-30 and it will be invalid
      // if user types 2022-02-2 this is valid because it's seen as 2022-02-20.
      // if user types 2022-02-1 this is valid because it's seen as 2022-02-10.
      // if user types 2022-02-0 this is valid
      removeDashesText.slice(6, 7).length > 0 &&
        (moment(`${year}-${('0' + month).slice(-2)}-${Number(date)}0`).isBetween(minDate, todayDate, undefined, '[]') || date === 0) &&
        updateDateFunc()
      break
    case 8:
      // validate 2 digits of date (e.g. validate 2022-02-02)
      moment(setDashesIndex(e, [3, 5], 10)).isBetween(minDate, todayDate, undefined, '[]') && updateDateFunc()
      break
    default:
      break
  }
}

export const setExpiryDateInput = (e: any, updateDateFunc: () => void) => {
  const removeDashesText = removeDashSpaceSpecialChar(e)
  // const minDate = new Date().toISOString().slice(0, 10);
  // const todayDate = new Date().toISOString().slice(0, 10);
  const minDate = '1900-01'
  const todayDate = moment(new Date()).add(10, 'y').format(i18n.t('dob-placeholder'))
  const monthNum1 = Number(removeDashesText.slice(4, 5))

  // validate input is equal or greater than today date input year
  const yearCompare = (yearDigit: number) => {
    const minDateNum = Number(minDate.slice(0, yearDigit))
    const todayDateNum = Number(todayDate.slice(0, yearDigit))
    const isBetweenMinTodayDate = Number(removeDashesText) >= minDateNum && Number(removeDashesText) <= todayDateNum
    return isBetweenMinTodayDate || removeDashesText === ''
  }

  // validate first 3 digits of year (e.g. validate 2, 20, 202)
  if (removeDashesText.length <= 3 && yearCompare(removeDashesText.length)) updateDateFunc()
  switch (removeDashesText.length) {
    // validate 4 digit year is between minYear and current year (e.g. validate 2022
    case 4:
      // remove the dash add the /
      moment(removeDashesText).isBetween(minDate.slice(0, 4), todayDate.slice(0, 4), undefined, '[]') && updateDateFunc()
      break
    case 5:
      // validate first digit of month (e.g. validate 2022-0)
      ;(monthNum1 === 0 || monthNum1 === 1) && updateDateFunc()
      break
    case 6:
      // validate 2 digits of month (e.g. validate 2022-02)
      moment(setDashesIndex(e, [3, 5], 7)).isBetween(minDate.slice(0, 7), todayDate.slice(0, 7), undefined, '[]') && updateDateFunc()
      break
    default:
      break
  }
}

export const convertUTCtoLocalTimezone = (utcDate: string) => {
  const formattedDate = moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss')

  return new Date(formattedDate)
}

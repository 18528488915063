import axios, { get } from './axios'
import queryString from 'query-string'
import { useState, useEffect, useMemo } from 'react'
import { updateToken, useContext } from '../Context'
import { apiI } from './interface'
import { AxiosRequestConfig } from 'axios'
import { addSessionStorage } from '../utils'

export const baseURL = async () => (await get(`${process.env.PUBLIC_URL}/config/config.json`))?.data.base_url
export const getConfig = async () => await get(`./config/config.json`)
// export const setBaseURL = (base_url: string) => { axios.defaults.baseURL = base_url; }
export const setAuthToken = () => {} //axios.defaults.headers.common = { 'Authorization': `Bearer ${getToken()}` }
export const getHash = (hash: string) =>
  new URLSearchParams(window.location.hash).get(`#${hash}`) || (window.localStorage.getItem('iaa-pei') ? JSON.parse(window.localStorage.getItem('iaa-pei') || '""')?.[hash] : '')
export const getResetToken = () => new URLSearchParams(window.location.hash).get('reset_token')
export const getQuerySearch = () => queryString.parse(window.location.search)

const useApi = ({ url, method, data, requiresAuth = true, withCredentials = false }: apiI) => {
  const {
    state: {
      config: { base_url },
      token,
    },
    dispatch,
  } = useContext()

  const [isLoading, setIsLoading] = useState(false)

  const endpoint = async ({ data } = { data: {} }) => {
    try {
      setIsLoading(true)

      let axiosParameters: AxiosRequestConfig = {
        method,
        url: base_url + url,
        data,
        withCredentials,
      }

      if (requiresAuth === true && !withCredentials) {
        axiosParameters.headers = { Authorization: `Bearer ${token}` }
      }

      const response: any = await axios(axiosParameters)

      const { headers } = response

      if (headers['x-auth-api']) {
        const token = headers['x-auth-api']
        addSessionStorage('idstore-token', token)
        dispatch(updateToken(token))
      }

      if (method === 'get' || method === 'put') {
        response.isSuccess = response?.status === 200
      }
      if (method === 'post') {
        response.isSuccess = response?.status === 200
        response.isSuccessValidate = response?.status === 200 && response?.data?.statusCode === 1
        response.isValidateError = response?.status === 200 && response?.data?.statusCode === 2
        response.isAttemptError = response?.status === 200 && response?.data?.statusCode === 3
        response.isDuplicateError = response?.status === 200 && response?.data?.statusCode === 4
        response.isRedirect = response?.status === 302
      }
      return {
        response,
        error: {},
      }
    } catch (error: any) {
      error.isInvalidError = error.response?.status === 400
      // error.isUnauthorizedError = error.response?.status === 401;
      error.isForbiddenError = error.response?.status === 403
      // TO DO: ask backend to send 404 for this error
      // error.isNotFoundError = error.response?.status === 404;
      error.isNotFoundError = error.response?.status === 401
      error.isSessionTimedOutError = error.response?.status === 408
      error.isConflictError = error.response?.status === 409
      error.isTooManyAttemptError = error.response?.status === 429
      error.isInternalServerError = error.response?.status === 500
      error.isGeneralError = error.response?.status !== 200 && error.response?.status !== 408
      return {
        response: {},
        error,
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { endpoint, isLoading }
}

// note: the only thing that returns response data: isRedirect

const useTriggeredGet = ({ url, requiresAuth = true, withCredentials = false }: apiI) => useApi({ url, method: 'get', requiresAuth, withCredentials })

const useTriggeredPost = ({ url, requiresAuth = true, withCredentials = false }: apiI) => useApi({ url, method: 'post', requiresAuth, withCredentials })

const useTriggeredPut = ({ url, requiresAuth = true }: apiI) => useApi({ url, method: 'put', requiresAuth })

const useGet = ({ url, data = {}, requiresAuth = true, withCredentials }: apiI) => {
  const [response, setResponse] = useState<any>(null)
  const [error, setError] = useState<any>(null)

  const { endpoint, isLoading } = useApi({
    url,
    method: 'get',
    requiresAuth,
    withCredentials,
  })

  useEffect(() => {
    const callEndpoint = async () => {
      const result: any = await endpoint({ data })
      if (Object.keys(result.error).length) {
        setError(result.error)
      } else {
        setResponse(result.response)
      }
    }
    callEndpoint()
    // eslint-disable-next-line
  }, [])

  return {
    response,
    error,
    isLoading,
  }
}

const usePost = ({ url, data = {}, requiresAuth = true }: apiI) => {
  const [response, setResponse] = useState<any>(null)
  const [error, setError] = useState<any>(null)

  const { endpoint, isLoading } = useApi({
    url,
    method: 'post',
    requiresAuth,
  })

  useEffect(() => {
    const callEndpoint = async () => {
      const result: any = await endpoint({ data })
      if (Object.keys(result.error).length) {
        setError(result.error)
      } else {
        setResponse(result.response)
      }
    }
    callEndpoint()
    // eslint-disable-next-line
  }, [])

  return {
    response,
    error,
    isLoading,
  }
}

const usePut = ({ url, data = {}, requiresAuth = true }: apiI) => {
  const [response, setResponse] = useState<any>(null)
  const [error, setError] = useState<any>(null)

  const { endpoint, isLoading } = useApi({
    url,
    method: 'put',
    requiresAuth,
  })

  useEffect(() => {
    const callEndpoint = async () => {
      const result: any = await endpoint({ data })
      if (Object.keys(result.error).length) {
        setError(result.error)
      } else {
        setResponse(result.response)
      }
    }
    callEndpoint()
    // eslint-disable-next-line
  }, [])

  return {
    response,
    error,
    isLoading,
  }
}

const useIdps = () => {
  const { response, error, isLoading } = useGet({ url: '/idp' })
  const idps = useMemo(() => response || {}, [response])
  return { idps, error, isLoading }
}
const useTriggeredIdps = () => {
  const { endpoint: useIdps, isLoading } = useTriggeredGet({ url: '/idp' })
  return { useIdps, isLoading }
}

export const useTriggeredIDVHealthCardOnline = () => {
  const { endpoint: requestIDVHealthCardLink, isLoading } = useTriggeredGet({ url: '/verification/add-health-card' })
  return { requestIDVHealthCardLink, isLoadingIDVHealthCard: isLoading }
}

export const useTriggeredIDVUpdateHealthCardOnline = () => {
  const { endpoint: requestIDVUpdateHealthCardLink, isLoading } = useTriggeredGet({ url: '/profile-fetch-hc' })
  return { requestIDVUpdateHealthCardLink, isLoadingIDVUpdateHealthCard: isLoading }
}

const useAuthenticators = () => {
  const { response, error, isLoading } = useGet({
    url: '/authenticator/all',
    withCredentials: true,
  })
  const authenticators = useMemo(() => response || {}, [response])
  return { authenticators, error, isLoading }
}
const useTriggeredAuthenticators = () => {
  const { endpoint: useAuthenticators, isLoading } = useTriggeredGet({
    url: '/authenticator/all',
    withCredentials: true,
  })
  return { useAuthenticators, isLoading }
}

// register: Accepts a email and password to create a credential for a use
const useUpdateRegister = () => {
  const { response, error, isLoading } = usePost({ url: '/register' })
  const register = useMemo(() => response || {}, [response])
  return { register, error, isLoading }
}
const useTriggeredUpdateRegister = () => {
  const { endpoint: useUpdateRegister, isLoading } = useTriggeredPost({
    url: '/register',
    withCredentials: true,
  })
  return { useUpdateRegister, isLoading }
}

// Authenticator Register
const useAuthenticatorRegister = () => {
  const { response, error, isLoading } = usePost({
    url: '/authenticator/register',
    withCredentials: true,
  })
  const register = useMemo(() => response || {}, [response])
  return { register, error, isLoading }
}
const useTriggeredAuthenticatorRegister = () => {
  const { endpoint: useAuthenticatorRegister, isLoading } = useTriggeredPost({
    url: '/authenticator/register',
    withCredentials: true,
  })
  return { useAuthenticatorRegister, isLoading }
}

// Authenticator Selection
const useAuthenticatorMethod = () => {
  const { response, error, isLoading } = useGet({
    url: '/authenticator/user_method',
  })
  const user_method = useMemo(() => response || {}, [response])
  return { user_method, error, isLoading }
}
const useTriggeredAuthenticatorMethod = () => {
  const { endpoint: useAuthenticatorMethod, isLoading } = useTriggeredGet({
    url: '/authenticator/user_method',
    withCredentials: true,
  })
  return { useAuthenticatorMethod, isLoading }
}

// login: Accepts an email and password to log a user in directly using an account they have created
const useUpdateLogin = () => {
  const { response, error, isLoading } = usePost({ url: '/login' })
  const login = useMemo(() => response || {}, [response])
  return { login, error, isLoading }
}
const useTriggeredUpdateLogin = () => {
  const { endpoint: useUpdateLogin, isLoading } = useTriggeredPost({
    url: '/login',
    withCredentials: true,
  })
  return { useUpdateLogin, isLoading }
}

// Start Verification
const useVerification = () => {
  const { response, error, isLoading } = useGet({ url: '/verification/get-verified' })
  const verification = useMemo(() => response || {}, [response])
  return { verification, error, isLoading }
}

const useTriggeredVerification = (returnParam?: string) => {
	const { endpoint: useVerification, isLoading } = useTriggeredGet({ url: '/verification/get-verified' + (returnParam ? '?return=' + returnParam : '')});
	return { useVerification, isLoading };
  }
  

// OTP: Verifies one time password during login/account creation
const useUpdateOTP = () => {
  const { response, error, isLoading } = usePost({ url: '/otp' })
  const otp = useMemo(() => response || {}, [response])
  return { otp, error, isLoading }
}
const useTriggeredUpdateOTP = () => {
  const { endpoint: useUpdateOTP, isLoading } = useTriggeredPost({
    url: '/otp',
    withCredentials: true,
  })
  return { useUpdateOTP, isLoading }
}

// OTP: Verifies one time password during login/account creation
const useUpdateOTPResend = () => {
  const { response, error, isLoading } = usePost({ url: '/otp-resend' })
  const otpResend = useMemo(() => response || {}, [response])
  return { otpResend, error, isLoading }
}
const useTriggeredUpdateOTPResend = () => {
  const { endpoint: useUpdateOTPResend, isLoading } = useTriggeredPost({
    url: '/otp-resend',
    withCredentials: true,
  })
  return { useUpdateOTPResend, isLoading }
}

// OTP change: Verifies one time password for the change password flow
const useUpdateOTPChange = () => {
  const { response, error, isLoading } = usePost({ url: '/otp-change' })
  const otpResend = useMemo(() => response || {}, [response])
  return { otpResend, error, isLoading }
}
const useTriggeredUpdateOTPChange = () => {
  const { endpoint: useUpdateOTPChange, isLoading } = useTriggeredPost({
    url: '/otp-change',
  })
  return { useUpdateOTPChange, isLoading }
}

// OTP: create account flow
export const useUpdateOTPCreateAccount = () => {
  const { response, error, isLoading } = usePost({ url: '/otp-signup-user' })
  const otpCreateAccount = useMemo(() => response || {}, [response])
  return { otpCreateAccount, error, isLoading }
}
export const useTriggerUpdateOTPCreateAccount = () => {
  const { endpoint: useUpdateOTPCreateAccount, isLoading } = useTriggeredPost({
    url: '/otp',
    withCredentials: true,
  })
  return { useUpdateOTPCreateAccount, isLoading }
}

// Profile: Gets the users current profile associated with their session
const useProfile = () => {
  const { response, error, isLoading } = useGet({ url: '/profile' })
  const profile = useMemo(() => response || {}, [response])
  return { profile, error, isLoading }
}
const useTriggeredProfile = () => {
  const { endpoint: useProfile, isLoading } = useTriggeredGet({
    url: '/profile',
  })
  return { useProfile, isLoading }
}

// SWAT
export const useSwatIdentity = () => {
  const { response, error, isLoading } = useGet({ url: '/profile-fetch-identity' })
  const swat = useMemo(() => response || {}, [response])
  return { swat, error, isLoading }
}
export const useTriggeredSwat = () => {
  const { endpoint: useSwatIdentity, isLoading } = useTriggeredGet({
    url: '/profile-fetch-identity',
  })
  return { useSwatIdentity, isLoading }
}

// PostProfile: Update a users profile information
const useUpdateProfile = () => {
  const { response, error, isLoading } = usePost({ url: '/profile' })
  const profile = useMemo(() => response || {}, [response])
  return { profile, error, isLoading }
}
const useTriggeredUpdateProfile = () => {
  const { endpoint: useUpdateProfile, isLoading } = useTriggeredPost({
    url: '/profile',
  })
  return { useUpdateProfile, isLoading }
}

// Consent: Gets the status of signing consent documents
const useConsent = () => {
  const { response, error, isLoading } = useGet({ url: '/consent', withCredentials: true })
  const consent = useMemo(() => response || {}, [response])
  return { consent, error, isLoading }
}
const useTriggeredConsent = () => {
  const { endpoint: useConsent, isLoading } = useTriggeredGet({
    url: '/consent',
    withCredentials: true,
  })
  return { useConsent, isLoading }
}

// Consent: Update the status of signing consent documents
const useUpdateConsent = () => {
  const { response, error, isLoading } = usePost({ url: '/consent' })
  const consent = useMemo(() => response || {}, [response])
  return { consent, error, isLoading }
}
const useTriggeredUpdateConsent = () => {
  const { endpoint: useUpdateConsent, isLoading } = useTriggeredPost({
    url: '/consent',
  })
  return { useUpdateConsent, isLoading }
}

// Consent: Update the status of signing consent documents
const useUpdateWithdrawConsent = () => {
  const { response, error, isLoading } = usePost({
    url: '/consent/withdraw',
  })
  const consent = useMemo(() => response || {}, [response])
  return { consent, error, isLoading }
}
const useTriggeredUpdateWithdrawConsent = () => {
  const { endpoint: useUpdateConsent, isLoading } = useTriggeredPost({
    url: '/consent/withdraw',
  })
  return { useUpdateConsent, isLoading }
}

// Registration DLV: Verifies Drivers License
const useUpdateRegistrationDL = () => {
  const { response, error, isLoading } = usePost({
    url: '/registration/dlv',
  })
  const dlv = useMemo(() => response || {}, [response])
  return { dlv, error, isLoading }
}
const useTriggeredUpdateRegistrationDL = () => {
  const { endpoint: useUpdateRegistrationDL, isLoading } = useTriggeredPost({
    url: '/registration/dlv',
  })
  return { useUpdateRegistrationDL, isLoading }
}

// Registration HCV: Verifies Health Card
const useUpdateRegistrationHC = () => {
  const { response, error, isLoading } = usePost({
    url: '/registration/hcv',
  })
  const hc = useMemo(() => response || {}, [response])
  return { hc, error, isLoading }
}
const useTriggeredUpdateRegistrationHC = () => {
  const { endpoint: useUpdateRegistrationHC, isLoading } = useTriggeredPost({
    url: '/registration/hcv',
  })
  return { useUpdateRegistrationHC, isLoading }
}

// Verifies Voluntary ID
const useUpdateRegistrationVID = () => {
  const { response, error, isLoading } = usePost({
    url: '/registration/vid',
  })
  const opc = useMemo(() => response || {}, [response])
  return { opc, error, isLoading }
}
const useTriggeredUpdateRegistrationVID = () => {
  const { endpoint: useUpdateRegistrationVID, isLoading } = useTriggeredPost({
    url: '/registration/vid',
  })
  return { useUpdateRegistrationVID, isLoading }
}

// Registration OPCV: Verifies Ontario Photo Card
const useUpdateRegistrationOPC = () => {
  const { response, error, isLoading } = usePost({
    url: '/registration/opcv',
  })
  const opc = useMemo(() => response || {}, [response])
  return { opc, error, isLoading }
}
const useTriggeredUpdateRegistrationOPC = () => {
  const { endpoint: useUpdateRegistrationOPC, isLoading } = useTriggeredPost({
    url: '/registration/opcv',
  })
  return { useUpdateRegistrationOPC, isLoading }
}

// Get Password reset: Sends an email to the user with a password reset link.
const usePasswordReset = () => {
  const { response, error, isLoading } = usePost({
    url: '/password-reset',
    requiresAuth: false,
  })
  const passwordReset = useMemo(() => response || {}, [response])
  return { passwordReset, error, isLoading }
}
const useTriggeredPasswordReset = () => {
  const { endpoint: usePasswordReset, isLoading } = useTriggeredPost({
    url: '/password-reset',
    requiresAuth: false,
  })
  return { usePasswordReset, isLoading }
}

// Put Password reset: Resets a user's password
const useUpdatePasswordReset = () => {
  const { response, error, isLoading } = usePut({
    url: '/password-reset',
    requiresAuth: false,
  })
  const passwordReset = useMemo(() => response || {}, [response])
  return { passwordReset, error, isLoading }
}
const useTriggeredUpdatePasswordReset = () => {
  const { endpoint: useUpdatePasswordReset, isLoading } = useTriggeredPut({
    url: '/password-reset',
    requiresAuth: false,
  })
  return { useUpdatePasswordReset, isLoading }
}

// Change Email: Sends an email with an OTP for verification before changing a user's email address
const useUpdateChangeEmail = () => {
  const { response, error, isLoading } = usePut({ url: '/change-email' })
  const changeEmail = useMemo(() => response || {}, [response])
  return { changeEmail, error, isLoading }
}
const useTriggeredUpdateChangeEmail = () => {
  const { endpoint: useUpdateChangeEmail, isLoading } = useTriggeredPut({
    url: '/change-email',
  })
  return { useUpdateChangeEmail, isLoading }
}

// Save Email: Sends an email with an OTP for verification
const useUpdateSaveEmail = () => {
  const { response, error, isLoading } = usePut({ url: '/save-email' })
  const saveEmail = useMemo(() => response || {}, [response])
  return { saveEmail, error, isLoading }
}
const useTriggeredUpdateSaveEmail = () => {
  const { endpoint: useUpdateSaveEmail, isLoading } = useTriggeredPut({
    url: '/save-email',
  })
  return { useUpdateSaveEmail, isLoading }
}

// Change password: Changes a user's password
const useUpdateChangePassword = () => {
  const { response, error, isLoading } = usePut({ url: '/change-password' })
  const changePassword = useMemo(() => response || {}, [response])
  return { changePassword, error, isLoading }
}
const useTriggeredUpdateChangePassword = () => {
  const { endpoint: useUpdateChangePassword, isLoading } = useTriggeredPut({
    url: '/change-password',
  })
  return { useUpdateChangePassword, isLoading }
}

// Profile Change password
const useUpdateProfileChangePassword = () => {
  const { response, error, isLoading } = usePost({
    url: '/profile-password',
  })
  const changePassword = useMemo(() => response || {}, [response])
  return { changePassword, error, isLoading }
}
const useTriggeredProfileUpdateChangePassword = () => {
  const { endpoint: useUpdateProfileChangePassword, isLoading } = useTriggeredPost({
    url: '/profile-password',
  })
  return { useUpdateProfileChangePassword, isLoading }
}

// Profile Change Firstname and last name
const useUpdateProfileUpdateName = () => {
  const { response, error, isLoading } = usePost({ url: '/profile-name' })
  const changeName = useMemo(() => response || {}, [response])
  return { changeName, error, isLoading }
}
const useTriggeredProfileUpdateName = () => {
  const { endpoint: useUpdateProfileUpdateName, isLoading } = useTriggeredPost({
    url: '/profile-name',
  })
  return { useUpdateProfileUpdateName, isLoading }
}

// Profile Change Address
const useUpdateProfileUpdateAddress = () => {
  const { response, error, isLoading } = usePost({ url: '/profile-address' })
  const changeAddress = useMemo(() => response || {}, [response])
  return { changeAddress, error, isLoading }
}
const useTriggeredProfileUpdateAddress = () => {
  const { endpoint: useUpdateProfileUpdateAddress, isLoading } = useTriggeredPost({
    url: '/profile-address',
  })
  return { useUpdateProfileUpdateAddress, isLoading }
}

// Profile Change Preferred Name
const useUpdateProfileUpdatePreferredName = () => {
  const { response, error, isLoading } = usePost({ url: '/profile-preferred_name' })
  const changePreferredName = useMemo(() => response || {}, [response])
  return { changePreferredName, error, isLoading }
}
const useTriggeredProfileUpdatePreferredName = () => {
  const { endpoint: useUpdateProfileUpdatePreferredName, isLoading } = useTriggeredPost({
    url: '/profile-preferred_name',
  })
  return { useUpdateProfileUpdatePreferredName, isLoading }
}

// Profile Change Birthdate
const useUpdateProfileUpdateBirthdate = () => {
  const { response, error, isLoading } = usePost({ url: '/profile-birthdate' })
  const changeBirthdate = useMemo(() => response || {}, [response])
  return { changeBirthdate, error, isLoading }
}
const useTriggeredProfileUpdateBirthdate = () => {
  const { endpoint: useUpdateProfileUpdateBirthdate, isLoading } = useTriggeredPost({
    url: '/profile-birthdate',
  })
  return { useUpdateProfileUpdateBirthdate, isLoading }
}

// Profile Change Phone Number
const useUpdateProfileUpdatePhoneNumber = () => {
  const { response, error, isLoading } = usePost({ url: '/profile-phone_number' })
  const changePhoneNumber = useMemo(() => response || {}, [response])
  return { changePhoneNumber, error, isLoading }
}
const useTriggeredProfileUpdatePhoneNumber = () => {
  const { endpoint: useUpdateProfileUpdatePhoneNumber, isLoading } = useTriggeredPost({
    url: '/profile-phone_number',
  })
  return { useUpdateProfileUpdatePhoneNumber, isLoading }
}

// logout
const useLogout = () => {
  const { response, error, isLoading } = useGet({ url: '/logout' })
  const logout = useMemo(() => response || {}, [response])
  return { logout, error, isLoading }
}
const useTriggeredLogout = () => {
  const { endpoint: useLogout, isLoading } = useTriggeredGet({
    url: '/logout',
  })
  return { useLogout, isLoading }
}

//  Return Verification
const useVerificationReturn = () => {
  const { response, error, isLoading } = useGet({ url: '/verification/return' })
  const result = useMemo(() => response || {}, [response])
  return { result, error, isLoading }
}
const useTriggeredVerificationReturn = (state: string, error?: string) => {
  const errorParam = error ? `&error=${error}` : ''
  const { endpoint: useVerificationReturn, isLoading } = useTriggeredGet({ url: `/verification/return?state=${state}${errorParam}` })
  return { useVerificationReturn, isLoading }
}

//  Validate Code
const useVerificationCodeValidate = () => {
  const { response, error, isLoading } = usePost({
    url: `/verification/verification-code/validate`,
    withCredentials: true,
  })
  const result = useMemo(() => response || {}, [response])
  return { result, error, isLoading }
}
const useTriggeredVerificationCodeValidate = () => {
  const { endpoint: useVerificationCodeValidate, isLoading } = useTriggeredPost({
    url: `/verification/verification-code/validate`,
    withCredentials: true,
  })
  return { useVerificationCodeValidate, isLoading }
}

export {
  useApi,
  useTriggeredGet,
  useTriggeredPost,
  useTriggeredPut,
  useGet,
  usePost,
  useIdps,
  useTriggeredIdps,
  useUpdateRegister,
  useTriggeredUpdateRegister,
  useUpdateLogin,
  useTriggeredUpdateLogin,
  useUpdateOTP,
  useTriggeredUpdateOTP,
  useUpdateOTPResend,
  useTriggeredUpdateOTPResend,
  useUpdateOTPChange,
  useTriggeredUpdateOTPChange,
  useProfile,
  useTriggeredProfile,
  useUpdateProfile,
  useTriggeredUpdateProfile,
  useConsent,
  useTriggeredConsent,
  useUpdateConsent,
  useTriggeredUpdateConsent,
  useUpdateWithdrawConsent,
  useTriggeredUpdateWithdrawConsent,
  useUpdateRegistrationDL,
  useTriggeredUpdateRegistrationDL,
  useUpdateRegistrationHC,
  useTriggeredUpdateRegistrationHC,
  useUpdateRegistrationOPC,
  useTriggeredUpdateRegistrationOPC,
  usePasswordReset,
  useTriggeredPasswordReset,
  useUpdatePasswordReset,
  useTriggeredUpdatePasswordReset,
  useUpdateChangeEmail,
  useTriggeredUpdateChangeEmail,
  useUpdateSaveEmail,
  useTriggeredUpdateSaveEmail,
  useUpdateChangePassword,
  useTriggeredUpdateChangePassword,
  useUpdateProfileChangePassword,
  useTriggeredProfileUpdateChangePassword,
  useUpdateProfileUpdateBirthdate,
  useTriggeredProfileUpdateBirthdate,
  useUpdateProfileUpdatePhoneNumber,
  useTriggeredProfileUpdatePhoneNumber,
  useUpdateProfileUpdateName,
  useTriggeredProfileUpdateName,
  useUpdateProfileUpdateAddress,
  useTriggeredProfileUpdateAddress,
  useUpdateProfileUpdatePreferredName,
  useTriggeredProfileUpdatePreferredName,
  useUpdateRegistrationVID,
  useTriggeredUpdateRegistrationVID,
  useLogout,
  useTriggeredLogout,
  useAuthenticatorRegister,
  useTriggeredAuthenticatorRegister,
  useAuthenticatorMethod,
  useTriggeredAuthenticatorMethod,
  useAuthenticators,
  useTriggeredAuthenticators,
  useVerification,
  useTriggeredVerification,
  useVerificationReturn,
  useTriggeredVerificationReturn,
  useVerificationCodeValidate,
  useTriggeredVerificationCodeValidate,
}

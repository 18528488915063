import React, { useState } from 'react'
import { Button, Layout, Text, Title, LinkButton } from '../../components'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { PageI, errorI } from '../../components/ComponentInterface'

import { useTriggeredAuthenticatorRegister } from '../../services'
import { useContext, updateError } from '../../Context'
import ROUTES from '../../routes/common/constants'

const AddEmailAuthPage = ({ goBackLink, successFunc }: PageI) => {
  const { useAuthenticatorRegister: authenticatorRegister, isLoading } = useTriggeredAuthenticatorRegister()
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' })
  const { dispatch } = useContext()
  const userEmail = localStorage.getItem('userEmail')

  const error500 = () => setErrorObj({ ...errorObj, error: 'error-500-1' })

  const handleRegisterAuthenticator = async () => {
    try {
      const { response, error } = await authenticatorRegister({
        data: { method: 'EMAIL' },
      })

      if (response.isSuccess) {
        successFunc && successFunc()
      } else if (error.isUnauthorizedError) {
        dispatch(updateError(true))
      } else {
        error500()
      }
    } catch (e) {
      console.log('e', e)
      error500()
    }
  }

	return (
		<Layout
			header
			footer
			title={i18n.t("add-email-auth-title")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			className="add-email-auth progress-bar"
			stepper
			stepperLabel={i18n.t("account-creation")}
			stepperNumberofSteps={6}
			stepperCurrentStep={4}
		>
			<Title title={i18n.t("add-email-auth-title")} />
			<Text className="withVar lead-text" dataTestId="lead">
				{parse(
					i18n.t("add-email-auth-description-1", {
						var: userEmail ?? parse(i18n.t("example-email")),
					})
				)}
			</Text>
			<Text>{parse(i18n.t("add-email-auth-description-2"))}</Text>
			<Text>{parse(i18n.t("add-email-auth-description-3"))}</Text>
			<div className="button-container-2">
				<Button
					text={i18n.t("continue")}
					onClick={handleRegisterAuthenticator}
					isLoading={isLoading}
					className="continue"
					dataTestId="Button-primary"
				/>
			</div>
			<LinkButton
				url={`${ROUTES.Choose2FARoute}`}
				text={i18n.t("choose-different-auth")}
				dataTestId="Button-link"
			/>
		</Layout>
	);
};

export default AddEmailAuthPage

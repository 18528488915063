import { useEffect } from 'react'
import { useTriggeredLogout } from '../../services'
import { PageI } from '../../components/ComponentInterface'

import useSessionValidation from '../../common/hooks/useSessionValidation'

const LogoutPage = ({ otherRoutes: { loggingOut } }: PageI) => {
  const { useLogout: logout } = useTriggeredLogout()
  const { cleanUpUserSessionInfo } = useSessionValidation()

  useEffect(() => {
    const getLogout = async () => {
      await logout()
      cleanUpUserSessionInfo()
      return loggingOut()
    }

    getLogout()
    // eslint-disable-next-line
  }, [])
  return null
}

export default LogoutPage

import React, { useEffect, useState } from "react";
import {
	Button,
	EmailInput,
	Notification,
	PasswordInput,
	Recaptcha,
	Text,
	NameInput,
} from "..";
import parse from "html-react-parser";
import i18n from "../../i18n";
import { useContext, updateAccountCreation } from "../../Context";
import { InputI, InputValidateI, PageI } from "../ComponentInterface";
import useSubmitApi from "./hooks/useSubmitApi";
import useRecaptcha from "./hooks/useRecaptcha";
import useErrorHandling from "./hooks/useErrorHandling";

const CreateIdEmailForm = ({ successFunc, accesFormErrorObj }: PageI) => {
	const {
		state: {
			config,
			userInfo: { terms_accepted, accountCreation },

		},
		dispatch,
	} = useContext();

	const { submitApiCall, isLoading } = useSubmitApi();
	const {
		getToken,
		recaptchaKey,
		recaptchaLoaded,
		captchaRef,
		asyncScriptOnLoad,
		redoCaptcha,
	} = useRecaptcha(config);
	const { handleLocalErrors, error500, errorObj, handleApiError } =
		useErrorHandling();

	const [inputs, setInputs] = useState<InputI>({
		firstName: accountCreation?.first_name ?? "",
		middleName: accountCreation?.middle_name ?? "",
		lastName: accountCreation?.last_name ?? "",
		email: accountCreation?.email ?? "",
		createPassword: accountCreation?.createPassword ?? "",
		confirmPassword: accountCreation?.confirmPassword ?? "",
	});

	const [validInputs, setValidateInputs] = useState<InputValidateI>({
		isValidBtn: false,
		isValidFirstName: !!accountCreation?.first_name ,
		isValidMiddleName: !!accountCreation?.middle_name,
		isValidLastName: !!accountCreation?.last_name ,
		isValidEmail: !!accountCreation?.email,
		isValidPassword: !!accountCreation?.createPassword,
	});

	const [firstName, setFirstName] = useState(accountCreation?.first_name ?? "");
	const [middleName, setMiddleName] = useState(accountCreation?.middle_name ?? "");
	const [lastName, setLastName] = useState(accountCreation?.last_name ?? "");
	const [email, setEmail] = useState(accountCreation?.email ?? "");


	useEffect(() => {
		setValidateInputs({
			...validInputs,
			isValidBtn:
				(inputs.firstName
					? Boolean(validInputs.isValidFirstName)
					: true) &&
				(inputs.middleName
					? Boolean(validInputs.isValidMiddleName)
					: true) &&
				Boolean(validInputs.isValidLastName) &&
				Boolean(validInputs.isValidEmail) &&
				Boolean(inputs.createPassword) &&
				Boolean(inputs.confirmPassword),
		});
		// eslint-disable-next-line
	}, [
		validInputs.isValidFirstName,
		validInputs.isValidMiddleName,
		validInputs.isValidLastName,
		validInputs.isValidEmail,
		inputs.firstName,
		inputs.middleName,
		inputs.lastName,
		inputs.email,
		inputs.createPassword,
		inputs.confirmPassword,
	]);

	const submitClick = async () => {
		try {
			const hasLocalError = handleLocalErrors(inputs, validInputs);
			if (hasLocalError) return;

			dispatch(
				updateAccountCreation({
					...accountCreation,
					first_name: firstName,
					middle_name: middleName,
					last_name: lastName,
					email: email,
					createPassword: inputs.createPassword,
					confirmPassword: inputs.confirmPassword
				})
			);
			const updatedToken = await getToken();

			await submitApiCall(
				inputs,
				updatedToken,
				successFunc,
				handleApiError,
				dispatch,
				terms_accepted
			);
		} catch (e) {
			error500();
		}
	};

	const validateName = (input: any, validInput: any) => {
		return input ? validInput : false;
	};

	const handleInputChange = (
		e: { target: { value: any } },
		fieldName: any
	) => {
		const { value } = e.target;
		setInputs((prev) => ({
			...prev,
			[fieldName]: value,
		}));
	};

	const disabled = () => !validInputs.isValidBtn || !recaptchaLoaded;
	
	return (
		<>
			<form
				onSubmit={(e) => e.preventDefault()}
				className="create-id-email-form"
			>
				{(errorObj.errorNotification || accesFormErrorObj.errorNotification) && (
					<Notification
						text={parse(i18n.t(errorObj.error))}
						className="error"
						dataTestId="banner-error"
					/>
				)}
				<div className="textbox-form-container-2">
					<div>
						<NameInput
							type="first_name"
							onChange={(e) => {
								setFirstName(e.target.value);
								handleInputChange(e, "firstName");
							}}
							value={firstName}
							maxLength={150}
							minLength={1}
							label={i18n.t("name")}
							dataTestId={
								validateName(
									inputs.firstName,
									!validInputs.isValidFirstName
								)
									? "form-firstname-error"
									: "form-firstname"
							}
							onValidate={(e) => {
								setValidateInputs({
									...validInputs,
									isValidFirstName: e,
								});
							}}
							optional
							isError={validateName(
								inputs.firstName,
								!validInputs.isValidFirstName
							)}
						/>
						{validateName(
							inputs.firstName,
							!validInputs.isValidFirstName
						) && (
							<Text className="error" dataTestId="error">
								{i18n.t("invalid-characters")}
							</Text>
						)}
					</div>
					<div>
						<NameInput
							type="middle_name"
							onChange={(e) => {
								setMiddleName(e.target.value);
								handleInputChange(e, "middleName");
							}}
							value={middleName}
							maxLength={150}
							minLength={1}
							label={i18n.t("middle-name")}
							dataTestId={
								validateName(
									inputs.middleName,
									!validInputs.isValidMiddleName
								)
									? "form-midname-error"
									: "form-midname"
							}
							onValidate={(e) => {
								setValidateInputs({
									...validInputs,
									isValidMiddleName: e,
								});
							}}
							optional
							isError={validateName(
								inputs.middleName,
								!validInputs.isValidMiddleName
							)}
						/>
						{validateName(
							inputs.middleName,
							!validInputs.isValidMiddleName
						) && (
							<Text className="error" dataTestId="error">
								{i18n.t("invalid-characters")}
							</Text>
						)}
					</div>
					<div>
						<NameInput
							type="last_name"
							onChange={(e) => {
								setLastName(e.target.value);
								handleInputChange(e, "lastName");
							}}
							value={lastName}
							maxLength={150}
							minLength={1}
							label={i18n.t("last-name")}
							dataTestId={
								validateName(
									inputs.lastName,
									!validInputs.isValidLastName
								)
									? "form-lastname-error"
									: "form-lastname"
							}
							onValidate={(e) => {
								setValidateInputs({
									...validInputs,
									isValidLastName: e,
								});
							}}
							required
							isError={validateName(
								inputs.lastName,
								!validInputs.isValidLastName
							)}
						/>
						{validateName(
							inputs.lastName,
							!validInputs.isValidLastName
						) && (
							<Text className="error" dataTestId="error">
								{i18n.t("invalid-characters")}
							</Text>
						)}
					</div>
					<div>
						<EmailInput
							onChange={(e) => {
								setEmail(e.target.value);
								handleInputChange(e, "email");
							}}
							value={email}
							label={i18n.t("email")}
							onValidate={(e) => {
								setValidateInputs({
									...validInputs,
									isValidEmail: e,
								});
							}}
							dataTestId={
								validateName(
									inputs.email,
									!validInputs.isValidEmail
								) || errorObj.error.includes("email")
									? "form-email-error"
									: "form-email"
							}
							required
							isError={
								validateName(
									inputs.email,
									!validInputs.isValidEmail
								) || errorObj.error.includes("email")
							}
						/>
						{validateName(
							inputs.email,
							!validInputs.isValidEmail
						) && (
							<Text className="error" dataTestId="error">
								{i18n.t("invalid-email")}
							</Text>
						)}
						{errorObj.errorEmail && (
							<Text className="error" dataTestId="error">
								{i18n.t(errorObj.error)}
							</Text>
						)}
					</div>
				</div>
				<div className="textbox-form-container-3">
					<PasswordInput
						minLength={12}
						onChange={(e: any) => {
							handleInputChange(e, "createPassword");
						}}
						value={inputs.createPassword}
						label={i18n.t("create-password")}
						onValidate={(e) => {
							setValidateInputs({
								...validInputs,
								isValidPassword: e,
							});
						}}
						dataTestId={
							errorObj.error.includes("password")
								? "form-pw-error"
								: "form-pw"
						}
						description={parse(
							i18n.t("password-creation-description")
						)}
						required
						isError={errorObj.error.includes("password")}
					/>
				
					<PasswordInput
						minLength={12}
						onChange={(e: any) =>
							handleInputChange(e, "confirmPassword")
						}
						dataTestId={
							errorObj.error.includes("password")
								? "form-confirm-pw-error"
								: "form-confirm-pw"
						}
						value={inputs.confirmPassword}
						label={i18n.t("confirm-password")}
						required
						isError={errorObj.error.includes("password")}
					/>
				</div>
				{errorObj.error.includes("password") && (
					<Text className="error" dataTestId="error">
						{i18n.t(errorObj.error)}
					</Text>
				)}
				<Button
					onClick={submitClick}
					text={i18n.t("continue")}
					disabled={disabled()}
					isLoading={isLoading}
					className="continue"
					dataTestId="Button-primary"
				/>
				{recaptchaKey && (
					<Recaptcha
						recaptchaRef={captchaRef}
						recaptchaKey={recaptchaKey}
						onChange={redoCaptcha}
						onExpired={redoCaptcha}
						asyncScriptOnLoad={asyncScriptOnLoad}
					/>
				)}
			</form>
		</>
	);
};

export default CreateIdEmailForm;

import React from 'react';
import { IconListItemI } from './ComponentInterface';

const IconListItem = ({ children, logo, complete, dataTestId }: IconListItemI) => (
    <div className={`icon-list-item ${complete ? "complete" : ''}`} data-testid={dataTestId}>
        <i>{logo}</i>
        {children}
    </div>
)

export default IconListItem;

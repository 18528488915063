import React from 'react'
import parse from 'html-react-parser'

import i18n from '../../../../../i18n'
import { Button, ListItem, Text } from '../../../../../components'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../routes/common/constants'

const AccountHistory = () => {
  const history = useHistory()

  return (
    <div className="profile-information-session account-history">
      <div className="account-history">
        <Text className="subtitle" dataTestId="h3-acct-history">
          {parse(i18n.t('wallet-profile-account-history-title'))}
        </Text>
        <div data-testid="row-acct-history" className="profile-info-group">
          <ListItem
              title={`Review the consent history for your MyPEI Account`}
              value={`These are the digital services and sources of information that you have accessed using your MyPEI Account`}
              action={undefined}
              onClick={() => {}}
          />
          <Button 
            onClick={() => history.push(ROUTES.WalletConsentHistory)} 
            dataTestId="Button-secondary" 
            secondary 
            className="btn-account-history" 
            text={i18n.t('wallet-profile-account-history-review')}
          />
        </div>

      </div>
    </div>
  )
}

export default AccountHistory

import React from 'react';
import {
  Layout,
  ResetPasswordForm,
  Title
} from "../../components";
import i18n from '../../i18n';
import { PageI } from '../../components/ComponentInterface';
import { getResetToken } from '../../services';

const ResetPasswordPage = ({ successFunc }: PageI) => {
  return (
    <Layout header title={i18n.t('reset-password-title')} >
      <Title title={i18n.t('reset-password-title')} />
      <ResetPasswordForm successFunc={successFunc} resetToken={getResetToken()} />
    </Layout>
  )
}

export default ResetPasswordPage;
import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'

import useScrollToTop from '../common/hooks/useScrollToTop'
import ROUTES from '../routes/common/constants'

import LocalStorage from '../services/LocalStorage'
import useSessionValidation from '../common/hooks/useSessionValidation'
import SessionModal from '../components/session/SessionModal'

export const ProtectedWalletRoute = ({ component: Component, ...rest }: any) => {
  useScrollToTop()
  const history = useHistory()
  const { checkIDStoreToken } = useSessionValidation()

  const sessionWallet = LocalStorage.get('session')

  useEffect(() => {
    checkIDStoreToken()

    if (!sessionWallet) history.push(ROUTES.WalletWelcomeRoute)

    // eslint-disable-next-line
  }, [sessionWallet])

  return (
    <>
      <SessionModal />
      <Route
        {...rest}
        render={() => {
          return sessionWallet && <Component />
        }}
      />
    </>
  )
}

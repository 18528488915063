import React, { useEffect, useState } from 'react'
import { PasscodeFormI } from '../ComponentInterface'
import { PasscodeInput, Button, Spinner, Text } from '..'
import { useTriggeredUpdateOTP } from '../../services'
import i18n from '../../i18n'
import parse from 'html-react-parser'

import { useHistory } from 'react-router-dom'
import ROUTES from '../../routes/common/constants'

const AuthenticatorCodeForm = ({ successFunc, goBackLink, secondaryButtonDataTestId }: PasscodeFormI) => {
  const { useUpdateOTP: updateOtp, isLoading: isLoadingOtp } = useTriggeredUpdateOTP()
  const [passcode, setPasscode] = useState('')
  const [isValidPasscode, setIsValidPasscode] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isRedirecting, setIsRedirecting] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!isValidPasscode) return
    const verifyPasscodeOTP = async () => {
      const { response, error } = await updateOtp({
        data: { otp: passcode },
      })
      successErrorResponse(response, error)
    }
    verifyPasscodeOTP()
    // eslint-disable-next-line
  }, [isValidPasscode])

  const successErrorResponse = (response: any, error: any) => {
    if (response.isSuccess) {
      setIsRedirecting(true)
      successFunc && successFunc()
    } else if (error.isNotFoundError) {
      setErrorMessage('otp-enter-password-error')
      setIsValidPasscode(false)
    } else if (error.isTooManyAttemptError) {
      setErrorMessage('otp-too-many-attempts-error')
    } else if (error.isSessionTimedOutError) {
      setErrorMessage('otp-expired-error')
      setIsValidPasscode(false)
    } else if (error.isUnauthorizedError) {
      history.push(ROUTES.LogoutRoute)
    } else {
      setErrorMessage('otp-generic-error')
      setIsValidPasscode(false)
    }
  }
	return (
		<div className="authenticator-code-form">
			{isLoadingOtp || isRedirecting ? (
				<Spinner dark />
			) : (
				<>
					<PasscodeInput
						onChange={(e: any) => {
							setPasscode(e);
						}}
						onValidate={setIsValidPasscode}
						label={i18n.t("setup-authenticator-app-otp-title")}
						className={errorMessage ? "error" : ""}
						dataTestId="OTP"
						code={errorMessage ? passcode : ""}
					/>
					{errorMessage && (
						<Text className="error" dataTestId="error">
							{parse(i18n.t(errorMessage))}
						</Text>
					)}
					<Button
						secondary
						text={i18n.t("setup-authenticator-app-back")}
						onClick={goBackLink}
						dataTestId="Button-secondary"
					/>
				</>
			)}
		</div>
	);
};

  

export default AuthenticatorCodeForm

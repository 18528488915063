import ROUTES from '../../routes/common/constants'
import { getConfig } from '../../services'
import LocalStorage from '../../services/LocalStorage'
import { Idp } from './Idp'

const RevalidateSession = () => {
  const refresh = async () => {
    const { createLoginUrl } = Idp()

    const {
      wallet_config: { idp_authenticator_id },
    } = (await getConfig())?.data

    const url = await createLoginUrl(idp_authenticator_id, `${window.location.origin}${ROUTES.WalletCallback}`)

    LocalStorage.delete('session')

    window.location.href = url
  }

  return { refresh }
}

export default RevalidateSession

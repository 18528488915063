import React, { useEffect } from 'react'
import i18n from '../../../../i18n'

import Unverified from './components/Unverified'
import Verified from './components/Verified'
import Pending from './components/Pending'
import useVerifyUserStatus from '../../hooks/useVerifyUserStatus'

import { If, Layout, Spinner, Title } from '../../../../components'
import { VerifyStatus } from '../../../../common/constants/enum'

const OpionByStatus = ({ status }: { status: string }) => {
  switch (status) {
    case VerifyStatus.VERIFIED:
      return <Verified />
    case VerifyStatus.PENDING:
      return <Pending />

    default:
      return <Unverified />
  }
}

const WalletIdentityVerificationPage = () => {
  const { checkVerification, isLoading, status } = useVerifyUserStatus()

  useEffect(() => {
    checkVerification()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {isLoading ? (
        <div className="wallet-layout">
          <div className="loading">
            <Spinner dark />
          </div>
        </div>
      ) : (
        <Layout header footer logout>
          <If condition={!isLoading}>
            <Title title={i18n.t('wallet-identity-verification-title')} dataTestid={'identity-verification-title'} />
            <OpionByStatus status={status} />
          </If>
        </Layout>
      )}
    </>
  )
}

export default WalletIdentityVerificationPage

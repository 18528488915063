import React from "react";
import { Button, Layout, Text, Title } from "../../components";
import i18n from "../../i18n";
import check from "../../asset/icon_check.svg";
import { PageI } from "../../components/ComponentInterface";

const ResetPasswordSuccessPage = ({ successFunc }: PageI) => {
	return (
		<Layout
			header
			title={i18n.t("reset-password-success-title")}
			className="reset-password-success"
		>
			<div className="reset-password-success-check">
				<img src={check} alt={i18n.t("reset-password-success-title")} />
				<Title title={i18n.t("reset-password-success-title")} />
			</div>
			<Text>{i18n.t("reset-password-success-description")}</Text>
			<Button
				onClick={successFunc}
				text={i18n.t("reset-password-success-btn")}
				dataTestId="Button-primary"
			/>
		</Layout>
	);
};

export default ResetPasswordSuccessPage;

import { useHistory } from 'react-router-dom'
import { interpolateRoute } from '../../../common/utils/interpolateRoute'
import ROUTES from '../../../routes/common/constants'
import { getServices } from '../../../wallet/services/ServiceRegistry'
import { useTriggeredProfile } from '../../../services'
import { useState } from 'react'
import useConsent from './useConsent'
import { VerifyStatus } from '../../../common/constants/enum'

const useVerifyUserStatus = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [status, setStatus] = useState('none')


  const history = useHistory()
  const { storage } = getServices()
  const transaction_id = storage.get('cr_token')

  const { verifyAllPermissionsShared, containScopeToShowVerificationFlow, autoAgreeToConsent } = useConsent(transaction_id!)

  const { useProfile: profile } = useTriggeredProfile()

  const checkVerification = async () => {
    try {
      const showVerificationFlow = await containScopeToShowVerificationFlow()
      const hasAlreadySharedConsent = await verifyAllPermissionsShared()

      if (!showVerificationFlow) {
        if (!hasAlreadySharedConsent) {
          transaction_id && history.push(`${interpolateRoute(ROUTES.WalletConsentPage, { transactionID: transaction_id })}`, { replace: true })
        } else {
          await autoAgreeToConsent()
        }
      } else {
        const {
          response: { isSuccess, data },
        } = await profile()

        if (isSuccess) {
          const { verified_status, health_card_number } = data
          const isVerified = verified_status === VerifyStatus.VERIFIED
          setStatus(verified_status)

          if (isVerified && health_card_number && transaction_id) {
            if (hasAlreadySharedConsent) {
              await autoAgreeToConsent()
            } else {
              transaction_id && history.push(`${interpolateRoute(ROUTES.WalletConsentPage, { transactionID: transaction_id })}`, { replace: true })
            }
          }

          setIsLoading(false)
          return
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return {
    checkVerification,
    isLoading,
    status,
  }
}

export default useVerifyUserStatus

import { IWalletClientStorage } from '../wallet'

const LocalStorage: IWalletClientStorage = {
  get: (key: string, isObject?: boolean) => {
    const information = window.sessionStorage.getItem(key)

    return isObject && information ? JSON.parse(information) : information
  },
  set: (key, value: any, isObject?: boolean) => {
    window.sessionStorage.setItem(key, isObject ? JSON.stringify(value) : value)
  },
  delete: (key) => {
    window.sessionStorage.removeItem(key)
  },
}

export default LocalStorage
